import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './styles.css';
import * as firebase from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: 'AIzaSyB9EYFkZiOGvM2xShA54rTVWTuKvQlSNck',
  authDomain: 'guildpact-f1f33.firebaseapp.com',
  databaseURL: 'https://guildpact-f1f33.firebaseio.com',
  projectId: 'guildpact-f1f33',
  storageBucket: 'guildpact-f1f33.appspot.com',
  messagingSenderId: '698304798235',
  appId: '1:698304798235:web:b863d4f28f3cf53322a2be',
  measurementId: 'G-NKVWXDR64T',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

document.getElementById('appstore-btn').onclick = function (e) {
  e.preventDefault();
  analytics.logEvent("appstore-button-clicked");
  window.location = this.href;
};

document.getElementById('playstore-btn').onclick = function (e) {
  e.preventDefault();
  analytics.logEvent("playstore-button-clicked");
  window.location = this.href;
};
